import { useEffect } from "react";

export default function Hackfest() {
  useEffect(() => {
    window.location.href =
      "https://unstop.com/hackathons/inter-college-hackathon-advaita-tecno-cultural-fest-international-institute-of-information-technology-iiit-bh-857179";
  }, []);

  return null;
}
