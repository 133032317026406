import { useEffect } from "react";

export default function AfterMovie() {
  useEffect(() => {
    window.location.href =
      "https://youtu.be/FFiK2wsTXVs?si=PYtPWan_NayFAjCl";
  }, []);

  return null;
}
