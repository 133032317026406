import { useEffect } from "react";

export default function SwigNCode() {
  useEffect(() => {
    window.location.href =
      "https://www.codingninjas.com/studio/events/swig-n-code?utm_source=campus-ambassador&utm_medium=ET&utm_campaign=CNIIIT";
  }, []);

  return null;
}
