export const eventsData = [
  {
    title: "TECHNICAL EVENTS",
    id: "technical_events",
    subtitle: "DREAM IT. MAKE IT.",
    description:
      "Ready to unravel the tech giant within you? We have the best in store for you. Starting from hackathons to fun contests to tech talks, let's discover your innovative side! Think you are the best? Well you can make a statement here!!!",
    events: [
      {
        date: "29th Feb - 1st Mar",
        time: "10 PM - 12 PM(Next Day)",
        description:
          "Join us at Hackfest-Advaita, the inter-college hackathon where minds converge,knowledge flows, and innovation takes center stage. This event is your chance to collaborate with students from diverse colleges, share your tech prowess, and together, forge groundbreaking projects and ideas. Unleash your creativity and let the spirit of innovation thrive at Hackfest-Advaita!",
        image: "/event-images/Hackfest.webp",
        link: "https://unstop.com/p/inter-college-hackathon-international-institute-of-information-technology-iiit-bhubaneswar-857179",
        linkText: "Visit Page",
        // customPopUp: "Round 1 selections for the Hackathon is already over. You can look around the hackathon page, but new registrations are not allowed.",
        prize: 70000,
        contact:
          "COORDINATORS:-MD.EHTISHAM: 9198851103, SIPRAMOHANTY: 8280049224 ",
      },
      {
        date: "1st Mar",
        time: "3:00 PM - 6:00 PM",
        description:
          "Get ready to Swig-N-Code! This event is a race against time, where solo participants battle it out to solve a set of challenging questions in record time. It's not just about speed; there's a twist waiting to unfold that will keep you on the edge. Stay tuned for surprises that will elevate the excitement. Can you crack the code before time runs out",
        image: "/event-images/Swig-n-code.webp",
        link: "https://www.codingninjas.com/studio/events/swig-n-code?utm_source=campus-ambassador&utm_medium=ET&utm_campaign=CNIIIT",
        linkText: "Register",
        // eventinfo: "Rule book",
        //prize: 20000,
        prize: 10000,
        ruleBook:
          "https://drive.google.com/file/d/17MkHtHFtHwH929csu8vja2MYxgdWU1fV/view?usp=sharing",
        contact:
          "COORDINATORS:-SASWATBEHERA: 7327903685,SOUBHIKGON: 6370462354",
      },
      {
        date: "1ST MARCH",
        time: "9:00 AM - 12:00 PM",
        description:
          "Gear up for the ultimate off-road challenge at Dirt Rush! Race against time and conquer the dirt tracks in this adrenaline-packed R/C competition. Navigate your way through miniature monster truck obstacle courses, striving to be the quickest with minimal penalties. It's high-speed, off-road thrills – can you handle the dirt and emerge as the champion of Dirt Rush?",
        image: "/event-images/Dirt-Rush.webp",
        link: "https://unstop.com/p/dirt-rush-international-institute-of-information-technology-iiit-bhubaneswar-857200",
        linkText: "Register",
        prize: 10000,
      },
      {
        date: "1ST MARCH",
        time: "12:00 PM - 2:00 PM",
        description:
          "Guidance is the biggest weapon that helps us fight our way through success. How amazing does it feel when experienced Tech leads guide us? Well, this is obviously going to be an insightful seminar or talk related to new technologies by a popular speaker or community person. This is going to be a promising and engaging session for all the tech enthusiasts. So, come on and gain new insights and perspectives on the future of technology by the famous speaker.",
        image: "/event-images/Beyond-Boundaries.webp",
        eventinfo: "None",
        contact: "COORDINATORS:-PRRIYADASH: 7894023677,PUNITKUMAR: 9142403368",
      },
      {
        date: "1ST MARCH",

        time: "1:00 PM - 6:00 PM",

        description:
          "Step into the precision world of Line Follower! Unleash your technical prowess as you design a mobile marvel capable of detecting and flawlessly tracking lines on the floor. Equipped with infrared sensors, the Line Follower robot interprets the path, sends data to its processor, and maneuvers its way through predefined routes. Join us for a journey where technology meets precision in the Line Follower event – where every line is a challenge waiting to be conquered!",
        image: "/event-images/Line-Follower.webp",
        link: "https://unstop.com/p/line-follower-international-institute-of-information-technology-iiit-bhubaneswar-857177",
        linkText: "Register",
        prize: 10000,
      },
      {
        date: "2ND MARCH",
        time: "9:00 AM - 1:00 PM",
        description:
          "Get ready for soccer like never before – it's ROBOSOCCER time! Engineers, show off your sophisticated skills in this manual robot football extravaganza. Brace yourselves,soccer fans, as we bring you a whole new dimension of the game. Unleash your inner RONALDO or MESSI by designing bots that flip, kick, maneuver, and showcase unparalleled control. It's time to step off the sidelines and onto the pitch – the thrill awaits at ROBOSOCCER. Don't just watch, participate, and experience the excitement firsthand!",
        image: "/event-images/Robo-Soccer.webp",
        link: "https://unstop.com/p/robo-soccer-international-institute-of-information-technology-iiit-bhubaneswar-857211",
        linkText: "Register",
        prize: 10000,
      },
      {
        date: "28TH & 29TH FEB",
        time: "10:00  - 1:00 PM",
        description:
          "Dive into the world of cybersecurity with CyberSec-Hackathon! Unleash your hacking skills in this thrilling competition, solving challenges to capture hidden 'flags' strategically placed in the digital battleground. Are you ready to outsmart the virtual defenses and claim victory in the ultimate cybersecurity showdown?",
        image: "/event-images/CyberSec-Hackathon.webp",
        link: "https://unstop.com/o/R5VN0BE?lb=02mBqKH",
        linkText: "Register",
        //prize: "20000",
      },
      {
        date: "2ND MARCH",
        time: "12:00 PM - 3:00 PM",
        description:
          "Where Minds Dance and Codes Switch!Join this high-energy team-based programming competition, showcasing your ability to adapt swiftly, switch between problems seamlessly, and synchronize with your partner. Register in pairs, dive into distinct problem sets in separate rooms, and experience the thrill of switching places and mastering each other's code. Are you ready to code in rhythm and conquer the art of rapid problem-solving?",
        image: "/event-images/Switch-Coding.webp",
        link: "https://www.codingninjas.com/studio/events/switch-coding?utm_source=campus-ambassador&utm_medium=ET&utm_campaign=CNIIIT",
        linkText: "Register",
        eventinfo: "Rule book",
        prize: 10000,
        contact:
          "COORDINATORS:-CHIRANTANBEURA: 8018825114 , VENKAT: 8456955906",
      },
    ],
  },
  {
    title: "MANAGEMENT EVENTS",
    id: "_events",
    subtitle: "INNOVATE TO ELEVATE !",
    description:
      "Ignite your management skills! From product competitions to conclaves, join us to prove your prowess. Elevate your game, network with experts, and discover the leader in you!",
    events: [
      {
        date: "2nd MARCH",
        time: "9 AM - 12 PM ",
        description:
          "Explore the intricacies of product development with our Product Teardown event! Participants engage in a case study round, dissecting a given product to identify and solve challenges. Teams submit presentations by deadlines, and shortlisted ones present their final solutions to judges. Each team, comprising 2 to 4 participants, delves into improvements and proposes metrics for success. A total prize pool of Rs. 15,000 worth, plus certificates, awaits the winning team!",
        image: "/event-images/product-teardown.webp",
        link: "https://unstop.com/competitions/product-teardown-advaita-tecno-cultural-fest-international-institute-of-information-technology-iiit-bhubane-864351",
        linkText: "Visit Page",
        // customPopUp: "Round 1 selections for the Hackathon is already over. You can look around the hackathon page, but new registrations are not allowed.",
        prize: 7000,
      },
      {
        date: "1st MARCH & 2nd MARCH",
        time: "6:00 PM - 7:00 PM",
        description:
          "Dive into the future of innovation at our Innovation Odyssey: Startup Conclave on 1st and 2nd March! Witness visionary students and aspiring startups showcasing revolutionary products and ideas across diverse industries. Judged on innovation, feasibility, impact, market potential, and presentation skills, participants stand a chance to win a prize pool of Rs. 10,000 worth + Certificates. Don't miss this celebration of creativity, collaboration, and the entrepreneurial spirit!",
        image: "/event-images/startup-conclave.webp",
        link: "https://unstop.com/competitions/startup-conclave-advaita-tecno-cultural-fest-international-institute-of-information-technology-iiit-bhubane-864337",
        linkText: "Register",
        // eventinfo: "Rule book",
        prize: 7000,
      },
    ],
  },
  {
    title: "CULTURAL EVENTS",
    id: "cultural_events",
    subtitle: "VIBE YOUR SOUL AWAY",
    description:
      "Hey singers and dancers! Here's your chance to enchant the whole arena with your charming voice or set the stage on fire with your dazzling dance steps as you participate and compete with the best.",
    events: [
      {
        date: "28th FEB",
        time: "5:00 PM - 8:00 PM",
        description:
          "Amplify the energy and strike a chord at ROCKATHON, where bands collide in a symphony of fierce competition! Unleash the power of your music, own the stage, and let your sound echo through the halls of ROCKATHON. It's not just a contest; it's a battle of beats, a clash of chords, and a celebration of rock at its finest. Are you ready to make the crowd roar and claim your place in the ultimate musical showdown?",
        image: "/event-images/Rockathon.png",
        link: "https://unstop.com/p/rockathon-international-institute-of-information-technology-iiit-bhubaneswar-858114",
        linkText: "Register",
        prize: 25000,
      },

      {
        date: "29TH FEB",
        time: "9:00 AM - 1:00 PM",
        description:
          "Step into the spotlight at RANGBHOOMI – where the stage comes alive with a single take, a rush backstage, and an unforgettable theatre experience! Advaita 2024 invites you to witness the magic of diverse theatre groups on one stage, immersing you in the beauty of different genres. It's not just about entertainment; it's about creating impact and telling stories that shine. This is your chance to be a part of the drama, to captivate hearts, and make a lasting impression. Join us for an evening of theatrical brilliance!",
        image: "/event-images/theatre.png",
        link: "https://unstop.com/events/rangbhoomi-theatre-advaita-tecno-cultural-fest-international-institute-of-information-technology-iiit-bhubaneswar-858085",
        linkText: "Register",
        prize: 15000,
      },

      {
        date: "2nd MARCH",
        time: "3:00 PM - 8:00 PM",
        description:
          "Embark on a style odyssey with LA Mode – where sophistication meets innovation on the runway! Join 'LAMODE: A Fashion Odyssey' for a mesmerizing fashion walk that transcends ordinary style. Experience a journey through glamor, creativity, and timeless elegance. It's not just a contest; it's a celebration of fashion that tells a story with every stride. Step into the world of LA Mode and let your style shine in this extraordinary cultural event!",
        image: "/event-images/la mode.png",
        link: "https://unstop.com/p/la-mode-international-institute-of-information-technology-iiit-bhubaneswar-858129",
        linkText: "Register",
        prize: 30000,
      },
      {
        date: "1st MARCH",
        time: "10:00 AM - 2:00 PM",
        description:
          "Step into the streets of expression with PRADARSHAN! This event is not just a contest; it's a platform that unites the nation's best Nukkad teams. Watch as the streets come alive with impactful performances, using theatre to communicate social and political messages. PRADARSHAN is more than a contest; it's a movement, raising awareness and igniting change, one street play at a time. Join us in the art of street theatre and be a part of the narrative for a better world!",
        image: "/event-images/nukkad.png",
        link: "https://unstop.com/p/pradarshan-nukkad-international-institute-of-information-technology-iiit-bhubaneswar-858098",
        linkText: "Register",
        prize: 20000,
      },
      {
        date: "2ND MARCH",
        time: "9:00 AM - 1:00 PM",
        description:
          "Step into the captivating world of ACOUSTICA, a college fest where musical magic takes center stage. Anuraag offers solo singers the chance to showcase their enchanting vocals, while RapMania invites rhythmic wizards to cast spells through rap performances. In Amplified Rhythm, beatboxers engage in a mesmerizing duel, impressing judges with technicality, stage presence, and originality. ACOUSTICA promises a spellbinding experience, celebrating diverse musical talents in a magical setting.",
        image: "/event-images/acoustica.png",
        link: "https://unstop.com/p/acoustica-anuraag-international-institute-of-information-technology-iiit-bhubaneswar-858136",
        linkText: "Register",
        prizes: 14000,
      },
      {
        date: "2ND MARCH",
        time: "9:00 AM - 1:00 PM",
        description:
          "Gear up for an evening of unparalleled talent, where the stage will come alive with the beats of innovation, the rhymes of intellect, and the stories of a generation that refuses to be confined by conventions. RapMania – where engineering meets expression, and creativity knows no boundaries. Get ready to be immersed in a symphony of words and beats that redefine the very essence of rap. Let the competition begin!.",
        image: "/event-images/acoustica (2).png",
        link: "https://unstop.com/p/rap-mania-international-institute-of-information-technology-iiit-bhubaneswar-858140",
        linkText: "Register",
        prize: 14000,
      },
      {
        date: "2ND MARCH",
        time: "9:00 AM - 1:00 PM",
        description:
          "Beatboxing (also beat boxing) is a form of vocal percussion primarily involving the art of mimicking drum machines using one's mouth, lips, tongue, and voice. It may also involve vocal imitation of turntablism, and other musical instruments. Beatboxing today is connected with hip-hop culture, often referred to as the fifth element of hip-hop, although it is not limited to hip-hop music. The term beatboxing is sometimes used to refer to vocal percussion in general..",
        image: "/event-images/acoustica (3).png",
        link: "https://unstop.com/events/amplified-rhythm-beatboxing-competition-advaita-tecno-cultural-fest-international-institute-of-information-techno-858146",
        linkText: "Register",
        prize: 14000,
      },

      {
        date: "1ST MARCH",
        time: "9:00 AM - 1:00 PM",
        description:
          "Dance into the rhythm at Footloose! Solo and group dancers, unleash your moves in this electrifying competition where Western beats and Nrityakala blend seamlessly. Join the groove, set the stage on fire, and let your passion shine. It's not just a competition; it's a celebration of dance in all its forms. Get ready to dazzle and dominate at Footloose – where every step tells a story and the stage is yours to own!",
        image: "/event-images/footloose.png",
        link: "https://unstop.com/p/footloose-international-institute-of-information-technology-iiit-bhubaneswar-858108",
        linkText: "Register",
        prizes: 23000,
      },
      {
        date: "1ST MARCH",
        time: "2:00 PM - 7:00 PM",
        description:
          "Experience the explosive energy of our Street Dance Battle, Cypher. Dancers from diverse backgrounds will ignite the pavement with jaw-dropping moves in epic one-on-one battles. The streets transform into a rhythmic battleground, creating an unforgettable evening where only the slickest moves and dankest beats will reign supreme. Let the Battle begin!",
        image: "/event-images/cypher.png",
        link: "https://unstop.com/p/cypher-international-institute-of-information-technology-iiit-bhubaneswar-858118",
        linkText: "Register",
        prize: 6000,
        extraInfo:
          "If you wish to just participate in Cypher during Advaita, you can purchase an event pass on the spot for ₹100 instead of the 1 or 4 day passes.",
      },
    ],
  },

  {
    title: "ART",
    id: "art_events",
    subtitle: "DRAWING THE DESIRE",
    description:
      "Artist? Not an artist? Well it doesn't really matter. They say art lies all around us . Well we don't deny and nor would you after being a part of the plethora of amazing events we bring to you.",
    events: [
      {
        //ART CURRACY
        date: "1ST MARCH",
        time: "2:00 PM - 4:00 PM",
        description:
          "Dive into the realm of artistic brilliance at ART-CURRACY! Unleash your creativity in this dynamic team event where one becomes the storyteller, and the other, the artist. It's a race against time, a test of teamwork, and a celebration of talent like never before. Whether you're an art enthusiast or a curious newcomer, join us for a symphony of colors and creativity – where innovation meets imagination!",
        image: "/event-images/art_curracy.png",
        prize: 8000,
        link: "https://unstop.com/p/art-curracy-international-institute-of-information-technology-iiit-bhubaneswar-858176",
        linkText: "Register",
      },
      {
        //ACE-HUNT
        date: "1ST MARCH ",
        time: "9:00 AM - 1:00 PM",

        description:
          "Embark on an artistic adventure like never before with ACE-HUNT! Grab your friends, form a dynamic team of 3-4, and immerse yourselves in a quest filled with mysteries, puzzles, and the thrill of discovery. Decode clues, navigate twists, and unlock surprises that await your artistic prowess. It's not just an event; it's an exhilarating journey into the heart of creativity!",
        image: "/event-images/ace_hunt.png",
        link: "https://unstop.com/p/ace-hunt-international-institute-of-information-technology-iiit-bhubaneswar-858179",
        linkText: "Register",
        prize: 8000,
      },
      // {
      //   //CREATE-A-THON
      //   date: "29TH FEB",
      //   time: "9:00 PM - 1:00 PM",
      //   description:
      //     "Get ready to ignite your creativity at CREATE-A-THON – IIIT Bhubaneswar's digital stage for design enthusiasts! Unleash your artistic prowess using any design tool, collaborate with your team, and craft designs that not only look good but also do good. Dive into themes centered around social issues, where your creations become a catalyst for change. Don't miss out on this opportunity to design, inspire, and impact. Join the competition and let your creativity shine on the digital canvas!",
      //   image: "/event-images/create_a_thon.png",
      //   link: "https://unstop.com/p/create-a-thon-international-institute-of-information-technology-iiit-bhubaneswar-858184",
      //   linkText: "Register",
      //   prize: 8000,
      // },
      {
        //ARTISTIC ALCHEMY
        date: "29th FEB",
        time: "10:00 AM - 1:00 PM",
        description:
          "Experience the magic of collaboration in the enthralling world of ARTISTIC ALCHEMY! Dive into our Switching Canvases Challenge, where pairs of artists dynamically switch canvases, creating a visually captivating narrative of transformation. Witness the fluidity of artistic expression unfold against captivating themes. Don't miss this amazing chance to immerse yourself in the magic of collaborative artistry at its finest!",
        image: "/event-images/artistic_alchemy.png",
        link: "https://unstop.com/p/artistic-alchemy-international-institute-of-information-technology-iiit-bhubaneswar-858190",
        linkText: "Register",
        prize: 8000,
      },
    ],
  },
  {
    title: "GAMES",
    id: "game_events",
    subtitle: "STEP YOUR GAME UP",
    description:
      "Hola gamers and fun seekers!!! How can we start the party without ya all. We are officially sending the invitation to you for the ultimate battles. Let's see if you have what it takes to win. May the best say GG!",
    events: [
      {
        date: "29 FEB - 1ST MARCH",
        time: "10:00 AM - 6:00 PM",
        description:
          "There would be tournaments in popular games like Valorant PC, BGMI etc. Also there would be a wide range of tournaments and leagues for all skill levels. Whether you’re a beginner or a pro, you can find the perfect competition to challenge yourself and show off your skills. Not to mention there would be a variety of rewards and prizes for the top players.",
        image: "/event-images/e-sports.png",
        link: "https://unstop.com/p/e-sports-international-institute-of-information-technology-iiit-bhubaneswar-858272",
        linkText: "Register",
        prize: 10000,
      },
      {
        date: "29 FEB - 1ST MARCH",
        time: "10:00 AM - 6:00 PM",
        description:
          "There would be tournaments in popular games like Valorant PC, BGMI etc. Also there would be a wide range of tournaments and leagues for all skill levels. Whether you’re a beginner or a pro, you can find the perfect competition to challenge yourself and show off your skills. Not to mention there would be a variety of rewards and prizes for the top players.",
        image: "/event-images/e-sports (2).png",
        link: "https://unstop.com/events/e-sports-valorant-advaita-techno-cultural-fest-international-institute-of-information-technology-iiit-bhubaneswar-887248",
        linkText: "Register",
        prize: 10000,
      },
    ],
  },
  {
    title: "PHOTO GRAPHY",
    id: "photography_events",
    subtitle: "HEART OF A PERFECT CLICK",
    description:
      "Get ready to snap, shoot, and shine! Unleash your inner artist and your wildest imagination. Dive into the world of photography and learn the ins and outs of capturing stunning shots. Let's capture some unforgettable moments together!",
    events: [
      {
        //Short movie
        date: "28TH FEB to 2ND MARCH",
        // time: "9:00 AM - 11:00 AM",
        description:
          "Capture the essence of life through your lens at IMAGEN! It's more than just a photography contest; it's a celebration of visual storytelling. Join the series of photography and videography competitions curated to fuel your passion for cameras and frames. Unleash your creativity, freeze moments, and let your imagination unfold at IMAGEN – where every shot tells a story!",
        image: "/event-images/50.png",
        link: "https://unstop.com/events/imagen-short-story-film-competition-advaita-tecno-cultural-fest-international-institute-of-information-technology-858227",
        linkText: "Register",
        prize: 5000,
      },
      {
        //Theme Photgraphy
        date: "28TH FEB to 2ND MARCH",
        // time: "9:00 AM - 11:00 AM",
        description:
          "Capture the essence of life through your lens at IMAGEN! It's more than just a photography contest; it's a celebration of visual storytelling. Join the series of photography and videography competitions curated to fuel your passion for cameras and frames. Unleash your creativity, freeze moments, and let your imagination unfold at IMAGEN – where every shot tells a story!",
        image: "/event-images/43.png",
        link: "https://unstop.com/events/theme-photography-advaita-tecno-cultural-fest-international-institute-of-information-technology-iiit-bhubaneswar-858232",
        linkText: "Register",
        prize: 5000,
      },
      {
        //Advaita in 30 Seconds

        date: "28TH FEB to 2ND MARCH",
        // time: "9:00 AM - 11:00 AM",
        description:
          "Capture the essence of life through your lens at IMAGEN! It's more than just a photography contest; it's a celebration of visual storytelling. Join the series of photography and videography competitions curated to fuel your passion for cameras and frames. Unleash your creativity, freeze moments, and let your imagination unfold at IMAGEN – where every shot tells a story!",
        image: "/event-images/48.png",
        link: "https://unstop.com/events/reel-making-competition-advaita-tecno-cultural-fest-international-institute-of-information-technology-iiit-bhuban-858244",
        linkText: "Register",
        prize: 5000,
      },
      {
        //Potrait Photgraphy
        date: "28TH FEB to 2ND MARCH",
        // time: "9:00 AM - 11:00 AM",
        description: "Capture the essence of life through your lens at IMAGEN! It's more than just a photography contest; it's a celebration of visual storytelling. Join the series of photography and videography competitions curated to fuel your passion for cameras and frames. Unleash your creativity, freeze moments, and let your imagination unfold at IMAGEN – where every shot tells a story!",
        image: "/event-images/47.png",
        link: "https://unstop.com/events/portrait-photography-advaita-tecno-cultural-fest-international-institute-of-information-technology-iiit-bhubanesw-858241",
        linkText: "Register",
        prize: 5000,
      },
      {
        //IMAGEN
        date: "1ST MARCH",
        time: "11:00 AM - 2:00 PM",
        description:
          "Unlock the secrets of stunning photography at our immersive workshop for FREE of Cost! Join fellow enthusiasts to refine your skills, explore advanced techniques, and capture breathtaking moments.Elevate your passion for photography in this hands-on experience.Limited seats available – don't miss out!",
        image: "/event-images/workshop.png",
        // link: "https://unstop.com/workshops-webinars/photography-workshop-advaita-techno-cultural-fest-international-institute-of-information-technology-i-861204",
        // linkText: "Register",
      },
      {
        date: "29TH FEB to 2ND MARCH",
        // time: "9:00 AM - 11:00 AM",
        description:
          "Welcome to our captivating Photo Gallery Showcase, where each image tells a unique story through the lens of its creator. Explore a visual journey that transcends words, inviting you to interpret narratives, emotions, and perspectives captured in frozen moments.",
          image: "/event-images/photogallery.png",
        // link: "https://unstop.com/events/photo-gallery-advaita-tecno-cultural-fest-international-institute-of-information-technology-iiit-bhubaneswar-861185",
        // linkText: "Register",
      },
    ],
  },
  {
    title: "FOOD",
    id: "food_events",
    subtitle: "SAVOR THE TASTE",
    description:
      "Any foodies here? Well we do hope so coz we have a lot amazing flavours in our hands where you not only get to cook exotic delicacies but also get to flaunt your eating skills.",
    events: [
      {
        date: "29TH FEB",
        time: "11:00 AM - 2:00 PM",
        description:
          "Brace yourself for a fiery culinary challenge! The Parantha Eating Competition with Hot Chilli Sauce is back, and it's going to be hotter than ever. Contestants will have to chow down as many delicious, piping-hot paranthas smothered in fiery hot chili sauce as they can within a set time limit. The heat is on as participants compete to see who can handle the hottest sauce and eat the most paranthas. The winner will be the one who can eat the most paranthas in the given time limit and will be rewarded with a prize. Don't miss out on the thrilling action and the chance to taste some of the spiciest paranthas around. Mark your calendars and come join us to cheer on your favorite contestant and enjoy the delicious food.",
        image: "/event-images/sholle parathe.png",
        link: "https://unstop.com/p/sholle-parathe-international-institute-of-information-technology-iiit-bhubaneswar-858211",
        linkText: "Register",
        prize: 2000,
      },
      {
        date: "2ND MARCH",
        time: "9:00 AM - 2:00 PM",

        description:
          "Get ready for a Maggie-making extravaganza!!! Cook Maggie with a delicious punch. The competition will be a test of skill, creativity, and speed as participants race against the clock to create the most delicious and visually stunning Maggie. The rules are simple: each chef must use only the ingredients provided and complete their dish within the allotted time. The judges will be looking for originality, taste, and presentation. The stakes are high, as the winner will receive bragging rights as the ultimate Maggie master. Don't miss out on this Maggie-making action!",
        image: "/event-images/maggie_chef.png",
        link: "https://unstop.com/p/maggie-chef-international-institute-of-information-technology-iiit-bhubaneswar-858204",

        linkText: "Register",
        prize: 2000,
      },
      {
        date: "1ST MARCH",
        time: "11:00 AM - 2:00 PM",
        description:
          "Step into a world of culinary teamwork at STRINGDELICACY! This is not your average sack race – it's a gastronomic adventure. Gather your team, hop through challenges, and weave your way to victory in this lively and delicious TEAM SACK RACE EVENT. Get ready for a race that's as flavorful as it is fun!",
        image: "/event-images/stringed delicacy.png",
        link: "https://unstop.com/p/string-delicacy-team-sack-race-event-international-institute-of-information-technology-iiit-bhubaneswar-858203",

        linkText: "Register",
        prize: 2000,
      },
      {
        date: "1ST MARCH",

        time: "3:00 PM - 6:00 PM",

        description:
          "Get ready for a mouth-watering, gastronomic adventure! The Golgappa Eating Competition is here, and it's sure to be a blast. Contestants will have to devour as many crispy and tangy golgappas as they can within a set time limit. These golgappas will be filled with a variety of fillings such as potato, chana, and tamarind chutney. This event is open to all the foodies with diverse tastes, and the winner will be the one who eats the most golgappas in the given time limit and is awarded the title of 'Golgappa King/Queen' along with a prize.",

        image: "/event-images/golgappa_kings.png",
        link: "https://unstop.com/p/golgappa-king-international-institute-of-information-technology-iiit-bhubaneswar-858196",

        linkText: "Register",
        prize: 2000,
      },
    ],
  },
  {
    title: "SOCIAL CONNECT",
    id: "social_connect",
    subtitle: "HELP FOR HOPE",
    description:
      "Connect, build relationships, and foster a sense of belongingness in a supportive and engaging atmosphere. Make new friends, find a support network, have some fun, help support important causes and make a positive impact in the community.",
    events: [
      {
        date: "28TH FEB",
        time: "10:00 AM - 12:00 AM",
        description:
          "Advaita marathon Run For Blood - a purposeful journey to save lives through blood donation. Experience the unity of runners, all striving for a healthier community. Whether a seasoned runner or a beginner, every stride contributes to a collective effort for a more compassionate society. Lace up and join us in making a lasting impact on a crucial social cause.",
        image: "/event-images/marathon.png",
        prize: 20000,
        link: "https://unstop.com/p/marathon-run-for-blood-international-institute-of-information-technology-iiit-bhubaneswar-858276",
        linkText: "Register",
      },
      {
        date: "28TH FEB",
        time: "10:00 AM - 12:00 PM",
        description:
          "Blood donation at Advaita 2k24 is a noble act embodying selfless service.",
        image: "/event-images/blood donation camp.png",
        link: "https://unstop.com/p/blood-donation-international-institute-of-information-technology-iiit-bhubaneswar-861181",
        linkText: "Register",
      },
      {
        date: "28TH FEB",
        time: "5:00 PM - 7:00 PM",
        description:
          "Immerse yourself in the rich tapestry of Indian classical arts at the SPIC MACAY event during Advaita 2k24. As part of our cultural extravaganza, this event brings together renowned artists to showcase the vibrant heritage of classical music, dance, and other traditional art forms.",
        image: "/event-images/spic macay.png",
      },
      {
        date: "1ST MARCH",
        time: "2:00 PM - 5:00 PM",
        description:
          "Dive into a compelling pep talk, where the urgency of a crucial social cause sparks enthusiasm and a shared sense of purpose. The empowering dialogue not only fuels individual passions but also lays the foundation for unified action. Your voice becomes a catalyst for positive change, echoing a call to champion collective efforts. Together, every voice contributes to a ripple effect, creating a meaningful impact on the cause we collectively champion. Join the movement, and let's forge a path towards a better future—one resounding voice at a time.",
        image: "/event-images/umang.png",
      },
    ],
  },
];
