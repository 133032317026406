import { useEffect } from "react";

export default function Marathon() {
  useEffect(() => {
    window.location.href =
      "https://unstop.com/events/marathon-run-for-blood-advaita-tecno-cultural-fest-international-institute-of-information-technology-iiit-bhubane-858276";
  }, []);

  return null;
}
