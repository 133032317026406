import { useEffect } from "react";

export default function BloodDonation() {
  useEffect(() => {
    window.location.href =
      "https://unstop.com/p/blood-donation-international-institute-of-information-technology-iiit-bhubaneswar-861181";
  }, []);

  return null;
}
